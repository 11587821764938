import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeam, fetchTeamDatastash } from "../store/teams-actions";
import { fetchTeamPlayerInjuries } from "../store/players-actions";
import { fetchNHLTeamReserveListPlayers } from "../store/players-actions";
import useAuth from "../hooks/use-auth";
import MainBox from "../layout/MainBox";
import { StatusValueIndicator } from "./indicators";
import { timeSince, shortDateString, wasEdited } from "../utils";

import classes from "./TeamPlayerInjurySummary.module.css";
import playernote_classes from "./PlayerNote.module.css";

const TeamPlayerInjurySummary = ({
    teamId,
    tier,
    tieredPlayers,
    onPlayerClick,
    isLoadingHandler,
    narrowLayout = false,
}) => {
    const { checkPermission } = useAuth();

    const canViewPlayerAvailability = checkPermission(
        "core.can_view_player_status_updates"
    );

    const dispatch = useDispatch();
    const error = useSelector((state) => state.ui.notification);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        isLoadingHandler && isLoadingHandler(isLoading);
    }, [isLoading, isLoadingHandler]);

    const players = useSelector((state) => state.players.players);

    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[teamId];
    const ahlTeamId = team && team.affiliations[0];

    const teams_datastashes = useSelector((state) => state.teams.datastashes);
    const depth_chart_config =
        teams_datastashes &&
        teams_datastashes[teamId] &&
        teams_datastashes[teamId].depth_chart_config;

    const playerIds = useMemo(() => {
        return tieredPlayers.reduce((acc, tierPlayers) => {
            // For each tier, reduce the playerGroup arrays into a single array of player IDs
            const tierPlayerIds = tierPlayers.data.reduce(
                (acc, playerGroup) => {
                    // Map each playerGroup to its player IDs, and concatenate those IDs to the accumulator
                    return acc.concat(
                        playerGroup.players.map((player) => player.player.id)
                    );
                },
                []
            );
            // Concatenate the current tier's player IDs to the main accumulator and return it
            return acc.concat(tierPlayerIds);
        }, []);
    }, [tieredPlayers]);

    const playersMissing = useMemo(
        () =>
            playerIds.length === 0 ||
            playerIds.some((playerId) => !players[playerId]),
        [playerIds, players]
    );

    const playersStatusUpdates = useSelector(
        (state) => state.players.statusupdates
    );

    const playersLatestMedicalStatusUpdates = useMemo(() => {
        if (playersStatusUpdates) {
            return Object.entries(playersStatusUpdates).reduce(
                (playersMap, [playerId, playerStatusUpdates]) => {
                    // Find the most recent medical status update, if it exists.
                    // Status updates are sorted by date descending, so the first
                    // one is the most recent.
                    const medicalStatusUpdate = playerStatusUpdates.filter(
                        (statusUpdate) => {
                            return (
                                statusUpdate.status_category.name === "Medical"
                            );
                        }
                    );
                    const latestMedicalStatusUpdate =
                        medicalStatusUpdate.length > 0
                            ? medicalStatusUpdate[0]
                            : null;

                    playersMap[playerId] = latestMedicalStatusUpdate;
                    return playersMap;
                },
                {}
            );
        }
        return {};
    }, [playersStatusUpdates]);

    // Fetch the players' status updates for team and tier
    useEffect(() => {
        if (canViewPlayerAvailability && team && tier) {
            dispatch(fetchTeamPlayerInjuries(team.id, tier));
        }
    }, [canViewPlayerAvailability, team, tier, dispatch]);

    const injuredPlayers = useMemo(() => {
        if (
            playerIds.length === 0 ||
            !players ||
            !playersLatestMedicalStatusUpdates
        ) {
            return null;
        }

        return playerIds
            .reduce((injuredPlayersArray, playerId) => {
                // Return the player details if they exist, otherwise null
                // If the player details exist, create an array with all
                // active injuries
                const playerDetails = players[playerId]
                    ? {
                          ...players[playerId],
                          active_injury:
                              playersLatestMedicalStatusUpdates[playerId] &&
                              playersLatestMedicalStatusUpdates[playerId]
                                  .injury &&
                              !playersLatestMedicalStatusUpdates[playerId]
                                  .injury.end_date &&
                              playersLatestMedicalStatusUpdates[playerId],
                      }
                    : null;

                if (
                    playerDetails &&
                    playerDetails.active_injury &&
                    playerDetails.active_injury.status_value.label !==
                        "Non-Roster"
                ) {
                    injuredPlayersArray.push(playerDetails);
                }
                return injuredPlayersArray;
            }, [])
            .sort((a, b) => {
                const startDateA = new Date(a.active_injury.injury.start_date);
                const startDateB = new Date(b.active_injury.injury.start_date);

                // First, compare by start_date
                if (startDateA < startDateB) return 1;
                if (startDateA > startDateB) return -1;

                // If start_dates are equal, then sort by created_at
                const createdAtA = new Date(a.active_injury.injury.created_at);
                const createdAtB = new Date(b.active_injury.injury.created_at);

                if (createdAtA < createdAtB) return 1;
                if (createdAtA > createdAtB) return -1;

                // If both dates are equal
                return 0;
            });
    }, [playerIds, players, playersLatestMedicalStatusUpdates]);

    useEffect(() => {
        if (!injuredPlayers) {
            setIsLoading(true);
        }
    }, [injuredPlayers]);

    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            setIsLoading(true);
        }
    }, [teamId, teams, dispatch]);

    useEffect(() => {
        if (teams && ahlTeamId && !teams[ahlTeamId]) {
            dispatch(fetchTeam(ahlTeamId));
            setIsLoading(true);
        }
    }, [ahlTeamId, teams, dispatch]);

    useEffect(() => {
        if (playersMissing) {
            // If any players from the team reserve list are missing from
            // the players store, fetch the teams's reserve list
            dispatch(fetchNHLTeamReserveListPlayers(teamId));
            setIsLoading(true);
        }
    }, [teamId, playersMissing, dispatch]);

    useEffect(() => {
        if (!depth_chart_config) {
            dispatch(fetchTeamDatastash(teamId, "depth_chart_config"));
            setIsLoading(true);
        }
    }, [teamId, depth_chart_config, dispatch]);

    useEffect(() => {
        if (team && depth_chart_config && !playersMissing && injuredPlayers) {
            setIsLoading(false);
        }
    }, [team, depth_chart_config, playersMissing, injuredPlayers, dispatch]);

    return (
        <MainBox>
            {!canViewPlayerAvailability && (
                <div className={classes.no_access}>
                    You do not have access to view player injuries.
                </div>
            )}
            {canViewPlayerAvailability && (
                <>
                    <div className={classes.summary_title}>Injury Summary</div>
                    {injuredPlayers ? (
                        <div
                            className={
                                classes.injury_summary_table_container +
                                (narrowLayout ? " " + classes.narrow : "")
                            }
                        >
                            <div className={classes.injury_summary_table}>
                                {injuredPlayers.map((playerDetails) => {
                                    return (
                                        <div
                                            key={playerDetails.id}
                                            className={
                                                classes.player_injury_summary +
                                                (onPlayerClick
                                                    ? " " + classes.clickable
                                                    : "")
                                            }
                                            onClick={() =>
                                                onPlayerClick &&
                                                onPlayerClick(playerDetails.id)
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.player_injury_summary_item_container +
                                                    " " +
                                                    classes.player_injury_summary_player_name
                                                }
                                            >
                                                {playerDetails.known_name}
                                            </div>
                                            <div
                                                className={
                                                    classes.player_injury_summary_item_container +
                                                    " " +
                                                    classes.player_injury_summary_player_injury_details +
                                                    " " +
                                                    playernote_classes.player_note_header
                                                }
                                            >
                                                {playerDetails.active_injury
                                                    .injury.description +
                                                    " • " +
                                                    shortDateString(
                                                        playerDetails
                                                            .active_injury
                                                            .injury.start_date
                                                    )}
                                            </div>
                                            <div
                                                className={
                                                    classes.player_injury_summary_item_container +
                                                    " " +
                                                    classes.player_injury_summary_player_injury_status
                                                }
                                            >
                                                <StatusValueIndicator
                                                    statusValue={
                                                        playerDetails
                                                            .active_injury
                                                            .status_value
                                                    }
                                                    flipped={true}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes.player_injury_summary_item_container +
                                                    " " +
                                                    classes.player_injury_summary_player_injury_note
                                                }
                                            >
                                                <div>
                                                    {
                                                        playerDetails
                                                            .active_injury.text
                                                    }
                                                    <span
                                                        className={
                                                            classes.player_injury_summary_player_injury_note_owner
                                                        }
                                                    >
                                                        {" • " +
                                                            playerDetails
                                                                .active_injury
                                                                .owner
                                                                .first_name +
                                                            " " +
                                                            playerDetails
                                                                .active_injury
                                                                .owner
                                                                .last_name +
                                                            " • " +
                                                            timeSince(
                                                                playerDetails
                                                                    .active_injury
                                                                    .created_at
                                                            ) +
                                                            wasEdited(
                                                                playerDetails
                                                                    .active_injury
                                                                    .created_at,
                                                                playerDetails
                                                                    .active_injury
                                                                    .modified_at
                                                            )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : error ? (
                        <section>
                            <div>{error.title}</div>
                            <div>{error.message}</div>
                        </section>
                    ) : (
                        <div>Loading...</div>
                    )}
                </>
            )}
        </MainBox>
    );
};

export default TeamPlayerInjurySummary;
